import React, { Component } from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Header from "../components/header";


import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
// import ReactFullpage from '@fullpage/react-fullpage';

import { ReactTypeformEmbed } from 'react-typeform-embed';

import ScrollAnimation from 'react-animate-on-scroll';


import {Animated} from "react-animated-css";



Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);




class Enquiry  extends Component {
    componentDidMount(){
      window.location="/"
}


  constructor(props) {
        super(props);
        this.state = {
        }
    }

    


 

 

  render(){

    const post = this.props.data.allWordpressPage.nodes[0];
    const metatitle = post.acf.meta_title ;
    const metadescription = post.acf.meta_description;
    const focus_keyphrase = post.acf.focus_keyphrase; 
    const canonical_url = post.acf.canonical_url; 

    
   
    return(
        
      <div>
          <Layout>
          <Helmet>
          <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          {(() => {if(post.acf.canonical_url != '' && post.acf.canonical_url != null) {return (<link rel="canonical" href={canonical_url} /> )} })()} 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />
            </Helmet>
           
          <section class="countrie-banner-sec-hgttr">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="countrie-banner-inner-agrs">
                                <ol class="breadcrumb-prfs">
                                    <li class="breadcrumb-item"><a href="/">Home </a></li> 
                                    <li class="breadcrumb-item active"> Enquiry</li>
                                </ol>
                                <div class="text-box-urds wow fadeInUp animated" data-wow-duration="1s" data-wow-delay=".1s">
                 <ScrollAnimation animateIn='fadeIn'
   style = {{'animation-duration': '1s'}}>                      <h3>Get In Touch With Us</h3>
                                    <p>Fill out the questions below to connect with one of our expert admissions counsellors</p>
                         </ScrollAnimation>       </div>  
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section class="scrds-dvtr">
                         <div class="container">
                           
            <div class="col-md-12 flt-lft hgts-dvtrp-dnt">
                <ReactTypeformEmbed url="https://form.typeform.com/to/fULKIJAt?typeform-medium=embed-snippet"  class="tyrs-frd"/>
            </div>
             </div>
            </section>

           
            </Layout>
            </div>




            
            
    )

    
  }
}


export default Enquiry;
export const EnquiryPage = graphql `
    {
        allWordpressPage(filter: {wordpress_id: {in: 4935}}) {
            nodes {
              title
              wordpress_id
              acf {
                 
                meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
               
          }
        }
    }   
}
`
